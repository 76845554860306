img, picture {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
}

.h3 {
font-size: 20px;

}

.btn {
    padding: 8px 20px;
    display: inline-block;
    z-index: 100;
    &.btn-white {
    border: 1px solid #fff;
    cursor: pointer;
    }


}

body.template-portfolio {
  background: #fff;
}

.container_start-portfolio, .site-footer{
min-height: 300px;
background-color: #fff;
.wrap-text {
    position: absolute;
    left: 50%;
    top: 70px;
    width: 70%;
    -webkit-transform: translate(-50%, 0);
    //transform: translate(-50%, 0);
    color: #000;
    a {
      color: #000;
    }

}



@media (max-width:800px) {
  min-height: 250px;
    .col {
        width: 100%;
        float: left;
        height: auto;
        margin-bottom: 30px
    }
    .col_2 {
        padding-left: 0;
        margin-bottom: 60px
    }
    .wrap-text {
        position: absolute;
        left: 50%;
        top: 35px;
        width: 90%
    }
    .container {
        min-height: 1000px
    }
    .container_2 {
        margin-bottom: 80px
    }
}
@media (min-width:800px) {
  min-height: 300px;

}
}

.container_portfolio {

width: 100%;
//min-height: 1400px;

@media (min-width:800px) {
  h2 {
    font-size: 34px;
  }



}

}

.row-grid {
  display: flex;
  flex-wrap: wrap;
      .col {


        }

      .col-md-6 {
        width: 100%;

      }

      @media (min-width:800px) {
        .col-md-6 {
          width: 50%;

        }
      }

}


.wrap-grid-item {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    opacity: 1;
    // background-color: #ff0354;
    // opacity: 0.8;
    // background: rgb(2,0,36);
    // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);

    }



  .overlay {
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: blue;
    color: #fff;
    opacity: 0;
    cursor: pointer;
    transition: all 0.1s ease;
    .btn {
      position: relative;
      transition: all 0.9s ease;
      overflow: hidden;


    }
    p {
      font-size: 14px;
    }

  }

  .overlay-text {
    padding: 30px 20px;

  }

  &:hover {
    .overlay {
    opacity: 0.9;


    .btn {
      opacity: 1.0;



    }

    }

  }


  @media (min-width:800px) {
    .overlay {
      p {
        font-size: 16px;
      }
    }
    .overlay-text {
      padding: 130px 70px;
    }

  }

  @media (min-width:1500px) {
    .overlay {
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
      .btn {
        font-size: 20px;
      }
    }
    .overlay-text {
      padding: 130px 70px;
    }

  }

}

.site-footer {
  position: relative;
  background-color: #fff;

}
